// app/frontend/entrypoints/application.js


// Import Turbo Drive to enable Turbo features in Rails
import { Turbo } from "@hotwired/turbo-rails";
Turbo.start(); // Start Turbo for enhanced navigation and page loading

// Import Stimulus and set up application
import { Application } from "@hotwired/stimulus";

// Start Stimulus
const application = Application.start();

// Dynamically import all Stimulus controllers using Vite's import.meta.glob
const controllers = import.meta.glob("../controllers/*_controller.js"); // Adjust pattern as needed

// Register each controller with Stimulus
for (const path in controllers) {
  controllers[path]().then((module) => {
    const controllerName = path
      .split("/")
      .pop()
      .replace("_controller.js", "") // Remove suffix to get controller name
      .replace(/_/g, "-"); // Replace underscores with dashes to match Stimulus naming convention

    application.register(controllerName, module.default);
  });
}

// Import custom JavaScript files
import "../js/company_search"; // Adjust the path based on where your custom JS is located

// Import main CSS file managed by Vite
import "../stylesheets/application.css"; // Ensure the path matches your CSS file location
import "../stylesheets/company.css";



