// app/frontend/js/company_search.js
document.addEventListener('turbo:load', function() {
  const companySearchInput = document.querySelector('#company-search');

  if (companySearchInput) {
    const autocomplete = async (request, callback) => {
      try {
        const response = await fetch(`/companies/autocomplete?term=${encodeURIComponent(request.term)}`);
        const data = await response.json();
        callback(data.map(item => ({
          label: item.label,
          value: item.value
        })));
      } catch (error) {
        console.error('Error fetching autocomplete data:', error);
      }
    };

    const setupAutocomplete = () => {
      $(companySearchInput).autocomplete({
        source: (request, response) => autocomplete(request, response),
        minLength: 2,
        select: function(event, ui) {
          window.location.href = `/companies/${ui.item.value}`;
        }
      });
    };

    // Ensure jQuery UI is loaded before setting up autocomplete
    if (window.jQuery && $.ui) {
      setupAutocomplete();
    } else {
      console.warn('jQuery UI is not loaded.');
    }
  }
});
